import { Customer, SaveActionsRequest, ScheduleAgentAppointmentRequest } from '@ffq-app-shared/model/common.data.model';
import { createAction, props } from '@ngrx/store';


export const StartQuote = createAction('[FFQ - App] Start Quote', props<{ reqPayload: any }>());
export const StartQuoteSuccess = createAction('[FFQ-App] Start Quote Success', props<{ respData: any, reqPayload: any, ctrlData: any }>());
export const StartQuoteFail = createAction('[FFQ-App] Start Quote Fail', props<{ respErr: any }>());

// MDM Service actions
export const MDMRetrieve = createAction('[FFQ - App] MDM Retreive', props<{ reqPayload: any }>());
export const MDMRetrieveSuccess = createAction('[FFQ-App] MDM Retrieve Success',
props<{ respData: any, reqPayload: any, ctrlData: any }>());
export const MDMRetrieveFail = createAction('[FFQ-App] MDM Retrieve Fail', props<{ respErr: any }>());

// tslint:disable-next-line:max-line-length
export const SourcePhoneNumberService = createAction('[FFQ - App] Source Phone Number Service Call', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const SourcePhoneNumberServiceSuccess = createAction('[FFQ-App]  Source Phone Number Service Call Success', props<{ respData: any, ctrlData: any }>());
export const SourcePhoneNumberServiceFail = createAction('[FFQ-App]  Source Phone Number Service Call Fails', props<{ ctrlData: any }>());

export const RetrieveQuote = createAction('[FFQ-App] Retrieve Quote', props<{ reqPayload: any }>());
export const RetrieveQuoteSuccess = createAction('[FFQ-App] Retrieve Quote Success', props<{ ctrlData: any }>());
export const RetrieveQuoteFail = createAction('[FFQ-App] Retrieve Quote Fail', props<{ respErr: any, ctrlData: any }>());

export const RetrievePIRateSummary = createAction('[FFQ - App] Retrieve PI Rate', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const RetrievePIRateSummarySuccess = createAction('[FFQ-App] Retrieve PI Rate Success', props<{ ctrlData: any }>());
export const RetrievePIRateSummaryFail = createAction('[FFQ-App] Retrieve PI Rate Fail', props<{ respErr: any,  ctrlData: any }>());


export const GetProjectCodeStatus = createAction('[FFQ-App] Get Project Code Status', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const GetProjectCodeStatusSuccess = createAction('[FFQ-App] Get Project Code Status Success', props<{ respData: any, ctrlData: any }>());
export const GetProjectCodeStatusFail = createAction('[FFQ-App] Get Project Code Status Fail', props<{ respErr: any }>());

export const StaticContentService = createAction('[FFQ - App] Static Content Service Call');
// tslint:disable-next-line: max-line-length
export const StaticContentServiceSuccess = createAction('[FFQ-App]  Static Content Service Call Success', props<{ respData: any, ctrlData: any }>());
export const StaticContentServiceFail = createAction('[FFQ-App]  Static Content Service Call Failure', props<{ respErr: any }>());

export const GetCrossSellEncryptedData = createAction('[FFQ-App] Get Encrypted crosssell Data', props<{ reqPayload: any }>());
// tslint:disable-next-line:max-line-length
export const GetCrossSellEncryptedDataSuccess = createAction('[FFQ-App] Get Encrypted crosssell data Success', props<{ respData: any, ctrlData: any }>());
export const GetCrossSellEncryptedDataFail = createAction('[FFQ-App] Get Encrypted crosssell data Fail', props<{ respErr: any }>());

export const ChangeZipCode = createAction('[FFQ-App] Change Zip Code', props<{ reqPayload: any }>());

export const ValidateZipCode = createAction('[FFQ-App] Validate Zip Code', props<{ reqPayload: any }>());
export const ValidateZipCodeSuccess = createAction('[FFQ-App] Validate Zip Code Success', props<{ respData: any }>());
export const ValidateZipCodeFail = createAction('[FFQ-App] Validate Zip Code Fail', props<{ respErr: any }>());


// TODO  Currently not being used.
export const SubmitYourInfoSuccess = createAction('[FFQ-App] Submit YourInfo Success', props<{ respData: any, ctrlData: any }>());
export const SubmitYourInfoFail = createAction('[FFQ-App] Submit YourInfo Fail', props<{ respErr: any, ctrlData: any }>());
export const SubmitYourInfoControlDataClear = createAction('[Auto-App] Clear Unwanted Control Data', props<{ ctrlData: any }>());

export const AssignAgent = createAction('[FFQ-App] Assign Agent', props<{ reqPayload: any }>());
export const AssignAgentSuccess = createAction('[FFQ-App] Assign Agent Success', props<{ respData: any, ctrlData: any }>());
export const AssignAgentFail = createAction('[FFQ-App] Assign Agent Fail', props<{ respErr: any, ctrlData: any }>());
export const AssignAgentControlDataClear = createAction('[Auto-App] Clear Unwanted Control Data', props<{ ctrlData: any }>());

// Updating for Auto Navigation
export const UpdateAutoNavStatus = createAction('[FFQ-App] Change Auto Navigation Status', props<{ reqPayload: any }>());
// Updating for Business Navigation
export const UpdateBusinessNavStatus = createAction('[FFQ-App] Change Business Navigation Status', props<{ reqPayload: any }>());
// Updating for Home Navigation
export const UpdateHomeNavStatus = createAction('[FFQ-App] Change Home Navigation Status', props<{ reqPayload: any }>());
/** clear the submit your info response and its control data when customer changes state */
export const ClearYourInfoDataAndControlData = createAction('[FFQ-App-YourInfo] Clear Submit Yourinfo Data and Control Data');
/** clear zip code error and validation message  */
export const ClearZipValidationErrorMessage = createAction('[FFQ-App-YourInfo-Vehicle] Clear Zip Validation Error Message');
// tslint:disable-next-line: max-line-length
export const UpdateCommunicationDetailsOnSuccess = createAction('[Auto-Driver] Update PNI`s Communication Data on SavePNI Success', props<{ respData: any }>());
export const SaveAndFinishLater = createAction('[FFQ-App] Save And Finish Later', props<{ reqPayload: any }>());
export const SaveAndFinishLaterSuccess = createAction('[FFQ-App] Save And Finish Later success', props<{ ctrlData: any }>());
export const SaveAndFinishLaterFail = createAction('[FFQ-App] Save And Finish Later fail', props<{ respErr: any, ctrlData: any }>());

export const BWAgentAssignment = createAction('[FFQ-App] BW Agent Assignment');
export const BWAgentAssignmentSuccess = createAction('[FFQ-App] BW Agent Assignment Success', props<{ respData: any, ctrlData: any }>());
export const BWAgentAssignmentFail = createAction('[FFQ-App] BW Agent Assignment Fail', props<{ respErr: any, ctrlData: any }>());



export const PopulateAppStoreOnRetrieval = createAction('[FFQ-App] Populate AppStore On Retreival', props<{ respData: any }>());
// tslint:disable-next-line:max-line-length
export const PopulateSessionStorageOnRetrieval = createAction('[FFQ-App] Populate Session Storage and Navigation Control Data On Retreival', props<{ sessionStorageData: any, navAndRetrieveCtrlData: any }>());
export const UpdateRetrieveDataStatus = createAction('[FFQ-App] Change RQ Auto Navigation Status', props<{ reqPayload: any }>());
export const PostToLegacy = createAction('[FFQ-App] Post To Legacy', props<{ reqPayload: any }>());
export const PostToLegacySuccess = createAction('[FFQ-App] Post To Legacy Success', props<{ ctrlData: any }>());
export const PostToLegacyFail = createAction('[FFQ-App] Post To Legacy Fail', props<{ ctrlData: any }>());

export const ValidateEmail = createAction('[FFQ-App] Validate Email', props<{ reqPayload: any }>());
export const ValidateEmailSuccess = createAction('[FFQ-App] Validate Email Success', props<{ respData: any }>());
export const ValidateEmailFail = createAction('[FFQ-App] Validate Email Fail', props<{ respErr: any }>());
export const ValidateDriverPage = createAction('[FFQ-App] Validate Driver Page', props<{ ctrlData: any }>());
export const UpdateFormEdited = createAction('[FFQ-App] Form Edited', props<{ reqPayload: any }>());
export const UpdateLastVisitedPage = createAction('[FFQ-App] Change Last Visited Page', props<{ reqPayload: any }>());
export const UpdateCurrentPage = createAction('[FFQ-App] Change Current Page', props<{ reqPayload: any }>());

export const SessionKeepAlive = createAction('[FFQ-App] Session Keep Alive', props<{ reqPayload: any }>());
export const SessionKeepAliveSuccess = createAction('[FFQ-App] Session Keep Alive Success', props<{ ctrlData: any }>());
export const SessionKeepAliveFail = createAction('[FFQ-App] Session Keep Alive Fail', props<{ respErr: any }>());

export const UpdateAppControlData = createAction('[App-ControlData] Update App Control Data', props<{ ctrlData: any }>());
export const UpdateSegmentEvent = createAction('[App-ControlData] Update Segment Event', props<{ ctrlData: any }>());
export const SubmitYourInfo = createAction('[Auto-YourInfo] Submit YourInfo', props<{ reqPayload: any }>());
export const UpdateNavStatus = createAction('[FFQ-App] Change Navigation Status For All', props<{ reqPayload: any }>());

// Start Quote with Number
export const StartQuoteWithQuoteNumber =
    createAction('[FFQ-App] Start Quote with the Quote Number', props<{ reqPayload: any }>());

export const StartQuoteWithQuoteNumberSuccess =
    createAction('[FFQ-App] Start Quote with the Quote Number Success', props<{ respData: any, ctrlData: any }>());

// tslint:disable-next-line: max-line-length
export const StartQuoteWithQuoteNumberFail = createAction('[FFQ-App] Start Quote with the Quote Number Fail', props<{ respErr: any, ctrlData: any }>());
export const UpdateLandingSrc = createAction('[FFQ-App] Update Landing Src Code', props<{reqPayload: any }>());
export const UpdateCrossSellStatus = createAction('[FFQ-App] Update Cross Sell Status', props<{ ctrlData: any }>());
export const UpdateRedirectionFlow = createAction('[FFQ-App] Update Redirection Flow', props<{ ctrlData: any }>());

export const AgentBusinessHour = createAction('[FFQ-App] Get Business Hour');
export const AgentBusinessHourSuccess = createAction('[FFQ-App] Get Business Hour success', props<{ respData: any, ctrlData: any }>());
export const AgentBusinessHourFail = createAction('[FFQ-App] Get Business Hour fail', props<{ respErr: any, ctrlData: any }>());

export const CheckChatStatus = createAction('[FFQ-App] Get Chat Status');
export const CheckChatStatusSuccess = createAction('[FFQ-App] Get Chat Status success', props<{ respData: any, ctrlData: any }>());
export const CheckChatStatusFail = createAction('[FFQ-App] Get Chat Status fail', props<{ respErr: any, ctrlData: any }>());

export const VehicleChangedAfterQuote = createAction('[FFQ-App] Vehicle module changed after perform rate', props<{ reqPayload: any }>());
export const DriverChangedAfterQuote = createAction('[FFQ-App] Driver module changed after perform rate', props<{ reqPayload: any }>());

export const PolicyStartDateChanged = createAction('[FFQ-App] Policy Start Date changed for InExp driver', props<{ reqPayload: any }>());


/** Action to update vehicle count */
export const UpdateVehicleCount = createAction('[Auto - Driver] Update Vehicle Count ', props<{ ctrlData: any }>());
/** Action to update driver count */
export const UpdateDriverCount = createAction('[Auto - Driver] Update Driver Count ', props<{ ctrlData: any }>());
/** Action to update gender */
export const UpdatePNIGender = createAction('[FFQ-App] Change PNI Gender', props<{reqPayload: any}>());
/** Action to Track Addtional Details page */

export const AdditionalDetailsPageStatus
    = createAction('[Auto - AddtionalDetails] Additional Details Page Status', props<{ ctrlData: any }>());

export const BackDoorCustomCodeImplementFlag
    = createAction('[FFQ-App]  Back Door Custom Quote Implementation Flag', props<{ ctrlData: any }>());

export const UpdateRefreshFlag
    = createAction('[FFQ-App]  Refresh Flag', props<{ ctrlData: any }>());

export const UpdateAppRootStoreDirectly = createAction('[Auto ] Refresh App Store Update', props<{ reqPayload: any }>());
/* Action to update any key in Data section */
export const UpdateAppData = createAction('[FFQ-App]  Update Element in App Data ', props<{ reqPayload: any }>());

export const FetchTownList = createAction('[FFQ-App] Town List');
export const FetchTownListSuccess = createAction('[FFQ-App] Town List Success', props<{ respData: any, ctrlData: any }>());
export const FetchTownListFail = createAction('[FFQ-App] Town List Fail', props<{ respErr: any, ctrlData: any }>());

export const UpdateLastVisitedStep = createAction('[FFQ-App] Change Last Visited Step for segment tracking', props<{ reqPayload: any }>());
export const UpdatePpcSrcAvailabilityStatus = createAction('[FFQ-App] Update PpcSrc Availability Status', props<{ ctrlData: any }>());
// action toAupdate promo Id and campaign id
export const UpdatePromoIdCid = createAction('[FFQ-App] Update Promo Id and Cid', props<{reqPayload: any }>());
export const UpdateUtmParameters = createAction('[FFQ-App] Update Utm Parameters from Landing', props<{reqPayload: any }>());
export const UpdateCampaignParameters = createAction('[FFQ-App] Update Campaign Parameters from Landing', props<{reqPayload: any }>());
export const UpdateCustomerLastName = createAction('[FFQ-App] Update Customer Last Name in App Data', props<{reqPayload: any }>());
export const UpdateCustomerEmail = createAction('[FFQ-App] Update Customer Email in App Data', props<{reqPayload: any }>());
export const MultiQuoteData = createAction('[FFQ-App] Multi Quote Data', props<{ respData: any, request: any }>());
export const RetrieveQuoteRedirectionData = createAction('[FFQ-App] Retrieve Quote Redirection Data', props<{ respData: any }>());
export const UpdateBWCompanyCode = createAction('[FFQ-App] Update Bristol West Company Code', props<{reqPayload: any }>());
export const SendEmailToScheduleAgent = createAction('[FFQ-App] Send Email to Schedule Agent',
 props<{reqPayload: ScheduleAgentAppointmentRequest }>());

/* DNQ Save Action Service */
export const SaveActions = createAction('[FFQ-App] DNQ Save Actions', props<{reqPayload: SaveActionsRequest }>());
export const SaveActionsSuccess = createAction('[FFQ-App] DNQ SaveActions Success', props<{controlData: any }>());
export const SaveActionsFail = createAction('[FFQ-App] DNQ SaveActions fail', props<{ controlData: any  }>());

export const UpdatePrefillFlag = createAction('[FFQ-App] Update Prefill Flag', props<{ctrlData: object }>());
export const VehicleOrDriverChanged = createAction('[FFQ-App] Vehicle or Driver module changed', props<{ reqPayload: any }>());
export const UpdateCustomerDetails = createAction('[FFQ-App] Update customer details', props<{ respData: Customer }>());

export const GetBoundInd = createAction('[FFQ-App] Get Bound Ind');

export const UpdateBrandSelection = createAction(
    '[FFQ-App] Update brand selection',
    props<{ resp: string[] }>(),
);

export const ContactInfoSubmitted = createAction(
    '[FFQ - App] Contact Info submitted',
    props<{ res: boolean }>(),
  );
  